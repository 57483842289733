import { ChakraProvider } from '@chakra-ui/react';
import { type ReactNode, forwardRef } from 'react';

interface ProviderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme?: Record<string, any>;
  children: ReactNode;
}

export const Provider = forwardRef<typeof ChakraProvider, ProviderProps>(
  ({ children, theme, ...props }) => {
    return (
      <ChakraProvider {...props} theme={theme}>
        {children}
      </ChakraProvider>
    );
  },
);
Provider.displayName = 'Provider';
